import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Spelautomater`}</h1>
    <p>{`Att spela spelautomater på nätet är något som kan vara väldigt avkopplande och som med lite tur kan ge dig storvinsten upp till flera miljoner. Vi har nedan listat våra favoritter bland spelautomaterna. Läs mera om spelautomaterna och du kan prova alla spelautomaterna helt gratis på alla svenska nätcasino.`}</p>
    <p>{`Hall of Gods`}</p>
    <p>{`Elements The Awakening`}</p>
    <p>{`Wild Rockets`}</p>
    <p>{`Mega Fortune`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      